import React from "react"
import Layout from "../components/layout"

import SEO from "../components/seo"

import {
  MdPermContactCalendar,
  MdContactPhone,
  MdContactMail,
} from "react-icons/md"

const impressum = () => {
  return (
    <Layout>
      <SEO title="Impressum" />
      <h1>Impressum</h1>

      <div className="text-center">
        <MdPermContactCalendar className="display-2 h2 mt-4" />
        <h2>Anschrift</h2>
        Herausgeber & inhaltlich verantwortlich:
        <br />
        Ink and Feather Design Studios
        <br />
        z.H. Jay Fischer, Design Director
        <br />
        14 Lot Les Mendes
        <br />
        33930 Vendays-Montalivet
        <br />
        Frankreich
        <br />
        <MdContactMail className="display-2 h2 mt-4" />
        <h2>Email</h2>
        hallo@inkfs.com
        <br />
        <MdContactPhone className="display-2 h2 mt-4" />
        <h2>Telefon</h2>
        00 49 178 8 137 148
        <h2>Online-Streitbeilegung (OS)</h2>
        <p>
          Online-Streitbeilegung: Die Europäische Kommission stellt eine
          Plattform zur Online-Streitbeilegung (OS) bereit, die Sie unter
          https://ec.europa.eu/consumers/odr/ finden. Verbraucher haben die
          Möglichkeit, diese Plattform für die Beilegung ihrer Streitigkeiten zu
          nutzen.
        </p>
        <br />
        <p>
          Erstellt mit kostenlosem Datenschutz-Generator.de von Dr. Thomas
          Schwenke
        </p>
      </div>
    </Layout>
  )
}

export default impressum
